<template>
  <main class="register">
    <h1>사업자 회원가입</h1>
    <ValidationObserver v-slot="{ invalid }">
      <form @submit="onSubmit" @reset="onReset">
        <div>
          <div>
            <p>아이디</p>
            <ValidationProvider
              rules="alpha_dash|min:5|max:20|required"
              ref="refId"
              name="아이디"
              v-slot="{ errors }"
            >
              <input
                type="text"
                placeholder="아이디를 입력해주세요."
                v-model="loginid"
                ref="refIdInput"
                :disabled="submitStatus === 'PENDING'"
                :class="{ active: errors[0] }"
                @change="addInfo"
              />
              <span v-if="!errors[0] && loginid == ''">
                * 아이디는 5~20자의 영문 소문자, 숫자와 특수기호(_), (-)만 사용
                가능합니다.
              </span>
              <span style="bottom: 0">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div>
            <p>비밀번호</p>
            <ValidationProvider
              name="비밀번호"
              rules="required|password"
              ref="refPassword"
              vid="password"
              v-slot="{ errors }"
            >
              <input
                type="password"
                placeholder="비밀번호를 입력해주세요."
                v-model="pass"
                ref="refPasswordInput"
                :disabled="submitStatus === 'PENDING'"
                :class="{ active: errors[0] }"
                @change="addInfo"
              />
              <span v-if="!errors[0] && pass == ''"
                >* 7~16자 영문 대 소문자, 숫자 조합을 사용하세요.</span
              >
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div>
            <p>비밀번호 확인</p>
            <ValidationProvider
              name="비밀번호 확인"
              rules="confirmed:password|required"
              ref="refConfirmPass"
              v-slot="{ errors }"
            >
              <input
                type="password"
                placeholder="비밀번호를 입력해주세요."
                v-model="confirmPass"
                ref="refConfirmPassInput"
                :disabled="submitStatus === 'PENDING'"
                :class="{ active: errors[0] }"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div>
            <p>소속/단체명</p>
            <ValidationProvider
              name="소속/단체명"
              rules="required"
              ref="refCorporationName"
              v-slot="{ errors }"
            >
              <input
                type="text"
                placeholder="사업자등록증과 동일하게 입력해주세요."
                v-model="corporationName"
                ref="refCorporationNameInput"
                :disabled="submitStatus === 'PENDING'"
                :class="{ active: errors[0] }"
                @change="addInfo"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div>
            <p>사업자등록번호</p>
            <ValidationProvider
              name="사업자등록번호"
              rules="required|numeric|corporation"
              ref="refCorporationNumber"
              v-slot="{ errors }"
            >
              <input
                type="text"
                placeholder="(-) 없이, 숫자만 입력해주세요."
                v-model="corporationNumber"
                ref="refCorporationNumberInput"
                :disabled="submitStatus === 'PENDING'"
                :class="{ active: errors[0] }"
                @change="addInfo"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div>
            <p>담당자 성함</p>
            <ValidationProvider
              name="담당자 성함"
              rules="required"
              ref="refName"
              v-slot="{ errors }"
            >
              <input
                type="text"
                placeholder="성함을 입력해주세요."
                v-model="name"
                ref="refNameInput"
                :disabled="submitStatus === 'PENDING'"
                :class="{ active: errors[0] }"
                @change="addInfo"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div>
            <p>전화번호</p>
            <ValidationProvider
              name="전화번호"
              rules="required|numeric"
              ref="refTel"
              v-slot="{ errors }"
            >
              <input
                type="text"
                placeholder="(-) 없이, 숫자만 입력해주세요."
                v-model="tel"
                ref="refTelInput"
                :disabled="submitStatus === 'PENDING'"
                :class="{ active: errors[0] }"
                @change="addInfo"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div>
            <p>이메일주소</p>
            <ValidationProvider
              name="이메일주소"
              rules="required|email"
              ref="refEmail"
              v-slot="{ errors }"
            >
              <input
                type="email"
                placeholder="이메일주소를 입력해주세요."
                v-model="email"
                ref="refEmailInput"
                :disabled="submitStatus === 'PENDING'"
                :class="{ active: errors[0] }"
                @change="addInfo"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="agree_check">
          <h3>약관동의</h3>
          <div>
            <label
              for="all_agree"
              class="all_check"
              :class="{ active: isChecked }"
            >
              <input
                type="checkbox"
                id="all_agree"
                :disabled="submitStatus === 'PENDING'"
                v-model="all_term"
                @change="selectAll"
              />
              모든 약관에 동의합니다.
              <span class="checkmark"></span>
            </label>
          </div>
          <div v-for="(term, index) in terms" :key="index">
            <label :for="term.id" class="check_container">
              <input
                type="checkbox"
                :id="term.id"
                :value="term.value"
                :disabled="submitStatus === 'PENDING'"
                v-model="all_term"
                @change="select(term)"
              />
              <span class="checkmark"></span>
              {{ term.label }}
            </label>
            <a :href="term.href" target="_blank">자세히보기</a>
          </div>
          <p>{{ termsError }}</p>
        </div>

        <div class="btn_center">
          <base-button
            type="primary"
            nativeType="submit"
            :disabled="
              termsError != '' || invalid || submitStatus === 'PENDING'
            "
            >{{
              submitStatus === "PENDING" ? "회원가입중.." : "회원가입"
            }}</base-button
          >
        </div>
      </form>
    </ValidationObserver>
  </main>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import Dialog from "@/components/Dialog.vue";
export default {
  components: { BaseButton },
  data() {
    return {
      submitStatus: null,
      loginid: "",
      pass: "",
      confirmPass: "",
      corporationName: "",
      corporationNumber: "",
      name: "",
      tel: "",
      email: "",
      terms: [
        {
          id: "terms_agree",
          label: "이용약관 동의 (필수)",
          value: 1,
          href: "https://nextinnovation.notion.site/0afc96309d974fe9bb270d3520383df2",
        },
        {
          id: "privacy_agree",
          label: "개인정보처리방침 동의 (필수)",
          value: 2,
          href: "https://nextinnovation.notion.site/d3fd1690607f4015992a1678d03ebf43",
        },
        {
          id: "marketing_agree",
          label: "개인정보 제3자 제공동의 (선택)",
          value: 3,
          href: "https://nextinnovation.notion.site/3-1488fd5700974609b8e8c721859f85cf",
        },
      ],
      selectedAllTerms: false,
      isChecked: false,
      all_term: [],
      termInput: "",
      termPrivacy: "",
      termsError: "약관에 모두 동의해주세요.",
      spanId: false,
      spanPass: false,
    };
  },
  mounted() {
    this.submitStatus = null;
  },
  computed: {
    ...mapGetters(["getRegInfo"]),
  },
  methods: {
    ...mapMutations({
      regInfo: "regInfo",
    }),
    addInfo() {
      this.regInfo({
        loginid: this.loginid,
        pass: this.pass,
        passConfirm: this.passConfirm,
        corporationName: this.corporationName,
        corporationNumber: this.corporationNumber,
        name: this.name,
        phone: this.tel,
        email: this.email,
        tel: this.tel,
      });
    },
    selectAll() {
      this.all_term = [];
      if (this.selectedAllTerms) {
        this.selectedAllTerms = false;
        this.isChecked = false;
        this.all_term = [];
        this.termsError = "약관에 모두 동의해주세요.";
      } else {
        this.termsError = "";
        this.selectedAllTerms = true;
        this.isChecked = true;
        for (this.term in this.terms) {
          this.all_term.push(this.terms[this.term].value);
        }
      }
    },
    select() {
      if (
        this.all_term.includes(1) &&
        this.all_term.includes(2) &&
        this.all_term.includes(3)
      ) {
        this.selectedAllTerms = true;
        this.isChecked = true;
        this.termsError = "";
      } else if (this.selectedAllTerms) {
        this.selectedAllTerms = false;
        this.isChecked = false;
        if (!this.all_term.includes(1)) {
          this.termsError = "이용약관에 동의해주세요.";
        } else if (!this.all_term.includes(2)) {
          this.termsError = "개인정보 취급방침에 동의해주세요.";
        }
      } else if (!this.all_term.includes(1)) {
        this.termsError = "이용약관에 동의해주세요.";
      } else if (!this.all_term.includes(2)) {
        this.termsError = "개인정보 취급방침에 동의해주세요.";
      } else {
        this.termsError = "";
      }
    },
    async validation() {
      const refId = await this.$refs.refId.validate(),
        refPassword = await this.$refs.refPassword.validate(),
        refConfirmPass = await this.$refs.refConfirmPass.validate(),
        refCorporationName = await this.$refs.refCorporationName.validate(),
        refCorporationNumber = await this.$refs.refCorporationNumber.validate(),
        refName = await this.$refs.refName.validate(),
        refTel = await this.$refs.refTel.validate(),
        refEmail = await this.$refs.refEmail.validate();

      if (!refId.valid) {
        this.$refs.refIdInput.focus();
        return false;
      }
      if (!refPassword.valid) {
        this.$refs.refPasswordInput.focus();
        console.log(this.$refs.refPasswordInput);
        return false;
      }
      if (!refConfirmPass.valid) {
        this.$refs.refConfirmPassInput.focus();
        console.log(this.$refs.refConfirmPassInput);
        return false;
      }
      if (!refCorporationName.valid) {
        this.$refs.refCorporationNameInput.focus();
        console.log(this.$refs.refCorporationNameInput);
        return false;
      }
      if (!refCorporationNumber.valid) {
        this.$refs.refCorporationNumberInput.focus();
        console.log(this.$refs.refCorporationNumberInput);
        return false;
      }
      if (!refName.valid) {
        this.$refs.refNameInput.focus();
        console.log(this.$refs.refNameInput);
        return false;
      }
      if (!refTel.valid) {
        this.$refs.refTelInput.focus();
        console.log(this.$refs.refTelInput);
        return false;
      }
      if (!refEmail.valid) {
        this.$refs.refEmailInput.focus();
        console.log(this.$refs.refEmailInput);
        return false;
      }
      if (!this.all_term.includes(1) || !this.all_term.includes(2)) {
        return false;
      }
      return true;
    },
    async create() {
      await this.$store
        .dispatch("register", {
          loginid: this.loginid,
          pass: this.pass,
          corporationName: this.corporationName,
          corporationNumber: this.corporationNumber,
          name: this.name,
          tel: this.tel,
          email: this.email,
          termsAgree: this.all_term.includes(1) ? 1 : 0,
          privacyAgree: this.all_term.includes(2) ? 1 : 0,
          marketingAgree: this.all_term.includes(3) ? 1 : 0,
        })
        .then((response) => {
          // this.submitStatus = "OK";
          if (response.status == 200) {
            this.$router.push({
              name: "RegisterComplete",
            });
          }
        })
        .catch((message) => {
          this.submitStatus = "ERROR";
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: message,
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
        });
    },
    async onSubmit(evt) {
      evt.preventDefault();

      let isValidation = await this.validation();
      if (isValidation) {
        this.submitStatus = "PENDING";
        this.create();
      }
    },
    onReset(evt) {
      evt.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
